<template>
    <div class="page-container">
        <div class="banner-content">
            <div class="banner">
                <!--<img src="../../assets/banner/qiyejieshao_bg_banner.min.jpg" alt="">-->
                <img :src="bannerUrl" alt="">
            </div>
            <div class="page-title-content">
                <div class="page-title">
                    <div class="first-title">企业介绍</div>
                    <div class="two-title">专注PE重包装袋领域</div>
                </div>
            </div>
        </div>
        <div class="page-content-div">
            <div class="page-content">
                <div class="jianjie-content">
                    <p class="module-title">温州丹业包装公司</p>
                    <div class="text-div">
                        <div>
                            创建于2009年，位于有“中国塑编城”和“中国信用镇”之称的温州萧江。作为国内15家能专业生产FFS重包装袋之一的公司，注册资本3300万元，总投资5800万元，占地12000平米。
                        </div>
                        <div>
                            <span class="color-000000db">目前拥有四条FFS袋全自动生产线，包括性能的三层共挤吹膜机组，以及先进的专业印刷机、压花—插边机、电脑高速制袋机等。</span>
                            引进德国巴顿菲尔三层共挤吹膜机，让企业产能和产品性能再上一个台阶。
                        </div>
                        <p class="why">为什么选用凹版印刷？</p>
                        <div style="padding-left: 25px">
                            <strong class="color-000">1，印刷耐印力强</strong>
                            <div>凹版印刷采用印版套筒（俗称版辊）轮转印刷，使用寿命长，其最大的特点在于高产能，每幅印版在重新修复前最少承印量能达到百万次数乃至数百万次。</div>
                            <div class="color-000000db">在丹业所定制包装袋版面，工厂永久保存。</div>
                            <strong class="color-000">2，高速生产</strong>
                            <div>与其他印刷方法比较，高速生产是凹印的另一种优势，在印刷材料宽幅和印刷速度方面，凹印的速度甚至可以达到每分钟1，000m,而承印材料宽幅科大300~400mm信息参考自《印 谱--中国印刷工艺样本专业版》</div>
                            <div class="weight-text color-083a69">通过多项国际质量认证报告</div>
                            <div>先后通过了ISO9001-2008，食品安全生产许可证，危险品货物出口包装生产许可证（UN危包证），拥有ROHS、SGS、EMTEK,INTERVIEW、BV等国际检测报告，拥有完整、科学的质量管理体系。</div>
                            <div>设立了专职独立的质检部，拥有先进的摩擦系数仪、落镖测试仪、拉力测试仪等质检设备。从原材料进厂检验到成品出厂检验，每一个环节都确保质量稳定可靠。
                                公司主要产品是FFS袋、PE阀口袋，产品广泛用于化工粒料、粉料的包装，石化塑料粒子以及饲料产品等包装,以及农化肥产品领域。国内大型石化企业，如上海SECCO公司、上海石化、扬子石化、扬子巴斯夫公司、中海壳牌公司、中沙石化等，全部要求采用这一新型包装，在欧美工业发达国家，它已经广泛地替代了传统的复合编织袋而成为合成树脂包装领域的主流。</div>
                            <div class="weight-text color-083a69">温州丹业包装有限公司 始终坚持</div>
                            <div>
                                <ul class="jianchi-content">
                                    <li>1：规范的生产理念</li>
                                    <li>2：专业的技术</li>
                                    <li>3：平实的价格</li>
                                    <li>4：稳定的资源</li>
                                    <li>5：为客户风险共担的原则</li>
                                </ul>
                            </div>
                            <div class="weight-text color-083a69">为您提供良好服务！</div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                bannerUrl: ''
            }
        },
        created() {
            const banners = JSON.parse(localStorage.getItem('banners'));
            banners ? this.bannerUrl = banners[3]['imgUrl'] : this.bannerUrl = this.$url.backImg[3];
        }
    }
</script>

<style lang="scss" scoped>
    .page-container{
        font-family: PingFangSC-Regular;
    }

    .page-content-div{
        display: flex;
        justify-content: center;
        width: 100%;
        .page-content{
            width: 1200px;
        }
    }
    .module-title{
        text-align: center;
        color: rgba(5, 6, 16, 100);
        font-size: 26px;
        margin-bottom: 28px;
        margin-top: 40px;
        font-family: PingFangSC-Semibold;
    }
    .jianjie-content{
        .text-div{
            padding: 24px;
            border: 1px solid rgba(233, 233, 238, 1);
            line-height: 36px;
            color: rgba(114, 114, 122, 100);
            font-size: 16px;
        }
    }
    .color-083a69{
        color: #083a69;
    }
    .color-000000db{
        color: #000000db;
    }
    .jianchi-content{
        list-style: none;
        /*color: #083a69;*/
        color: #000000db;
        background: #083a690a;
        margin: 10px 0;
        padding: 10px 0 10px 40px;
    }
    .weight-text{
        font-weight: 600;
        font-size: 17px;
        margin-top: 10px;
    }
    .color-076364{
        color: #076364
    }
    .color-000{
        /*color: #000000c7*/
    }
    .why{
        font-style: italic;
        font-size: 20px;
        color: #083a69;
        margin: 10px 0;
    }
</style>